<template>
  <div style="height: 100%;">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>学习管理</el-breadcrumb-item>
        <el-breadcrumb-item>学习任务</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content" style="width: 100%; padding: 10px 20px;">
      <div class="left">
        <div class="title">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-jiagoufenxiao" />
          </svg>
          <span>班级选择</span>
        </div>
        <div class="depart">
          <taskTree
            ref="departments"
            @departmentId="addBatchTask"
            @add-ok="reFresh"
            @departmentById="getEmpByDepartId"
            @deleDepartAllEmply="deletByDepartId"
          />
        </div>
      </div>
      <div class="right">
        <div class="title">
          <Button :disabled="selectId.length <= 0" @click="addTask" class="btn" type="primary">
            <svg class="icon">
              <use xlink:href="#icon-xinzeng" link /></svg
            >设置学习任务
          </Button>
          <Button :disabled="selectId.length <= 0 || type === 1" @click="deteTask" class="btn" type="primary">
            <svg class="icon">
              <use xlink:href="#icon-clear" link /></svg
            >清除学习任务
          </Button>
          <el-radio-group v-model="type" size="small" style="margin-left: 15px;">
            <el-radio v-for="(l, lIndex) in 3" :key="lIndex" :label="lIndex">{{
              lIndex === 0 ? '全部' : lIndex === 1 ? '无学习任务' : '有学习任务'
            }}</el-radio>
          </el-radio-group>
        </div>
        <div class="table">
          <el-table class="taskTable" :data="data" @selection-change="selectTask" @row-click="sDetail">
            <el-table-column type="selection" width="55" />
            <el-table-column prop="name" label="姓名" show-overflow-tooltip>
              <template slot-scope="scope">
                <div style="white-space: nowrap;display: flex;align-items: center;text-overflow: ellipsis;">
                  <img width="30" height="30" src="../../../assets/defaultAvatar.svg" alt="" />
                  <span style="margin-left: 15px;width: 110px;overflow: hidden;text-overflow: ellipsis">{{ scope.row.name }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="depart" label="专业" show-overflow-tooltip />
            <el-table-column prop="tag" label="标签" show-overflow-tooltip />
            <el-table-column prop="countCourse" label="总课程数" show-overflow-tooltip />
            <el-table-column prop="partCourse" label="参加课程数" show-overflow-tooltip />
            <el-table-column label="任务进度" show-overflow-tooltip>
              <template slot-scope="scope">
                <span :style="scope.row.taskProgass < 60 ? 'color: red' : 'color: darkseagreen'"
                  >{{ Math.round(scope.row.taskProgass * 10000) / 100 }}%</span
                >
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <span class="tool-button" @click.stop="upLearnTask(scope.row)">修改</span>
                <span class="tool-button" @click.stop="deteTask(scope.row)">清除</span>
                <!--                      <Tooltip placement="left" content="修改学习任务">-->
                <!--                        <svg @click.stop="upLearnTask(scope.row)" class="icon" aria-hidden="true">-->
                <!--                          <use xlink:href="#icon-bianji3"/>-->
                <!--                        </svg>-->
                <!--                      </Tooltip>-->
                <!--                      <Tooltip placement="right" content="清除学习任务">-->
                <!--                        <svg @click.stop="deteTask(scope.row)" class="icon" aria-hidden="true">-->
                <!--                          <use xlink:href="#icon-clear"/>-->
                <!--                        </svg>-->
                <!--                      </Tooltip>-->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <Page
          class="yt-page"
          :class="'yt-page-' + (dataTotal.toString().length > 1 ? dataTotal.toString().length : 2)"
          show-elevator
          show-total
          show-sizer
          :page-size-opts="[10, 20, 40, 100]"
          :total="dataTotal"
          @on-change="handlePageNum"
          @on-page-size-change="changeSize"
          :current="pageNumber + 1"
        />
      </div>
      <div v-if="addLearningTask">
        <learnTask :title="setTitle" :open="addLearningTask" :departmentName="departmentName" @close="closeModal" @addLearnTask="submitTask" />
      </div>
      <DeleteModal :title="deteDetail" :value="IsDelete" @on-ok="IsdeteTask" @on-cancel="closeDeletModal" />
    </div>
  </div>
</template>

<script>
import learnTask from './type/learnTask'
import DeleteModal from '../../common/DeleteModal'
import taskTree from './type/taskTree'
import learn from '../../../api/learnTask'
import departmentApi from '../../../api/department'
import vueScroll from 'vuescroll'
export default {
  name: 'task',
  components: { learnTask, DeleteModal, taskTree, vueScroll },
  data() {
    return {
      departList: [],
      data: [],
      dataTotal: 15,
      pageNumber: 0,
      departmentName: '',
      addLearningTask: false,
      IsDelete: false,
      deteDetail: '是否删除该学员的学习任务',
      type: 0,
      userId: 0,
      pageSize: 10,
      departmentId: 0,
      selectId: [],
      setTitle: '',
      updateByUserId: 0
    }
  },
  watch: {
    type(val) {
      this.getLearnTaskByType()
    }
  },
  created() {
    this.sendTreeData()
  },
  mounted() {
    // this.userId = this.$store.state.user.info.userId
    this.getAllDepartment()
  },
  methods: {
    handlePageNum(size) {
      this.pageNumber = size - 1
      this.getLearnTaskByType()
    },
    changeSize(val) {
      this.pageSize = val
      this.getLearnTaskByType()
    },
    getAllDepartment() {
      this.selectId = []
      this.departList = []
      departmentApi.getDepartmentsTree().then(res => {
        this.departmentId = res.res.departmentId
        this.departList.push({
          name: res.res.name,
          id: res.res.departmentId,
          children: res.res.children
        })
        if (this.departmentId) {
          this.getLearnTaskByType()
        }
      })
    },
    getLearnTaskByType() {
      this.data = []
      // const type = this.getType()
      const type = this.type
      const searchStudyTaskDTO = {
        departmentId: this.departmentId,
        pageNum: this.pageNumber,
        pageSize: this.pageSize,
        type: type
      }
      learn.getAllTask(searchStudyTaskDTO).then(res => {
        this.dataTotal = res.res.total
        res.res.data.map(res => {
          this.data.push({
            id: res.userId,
            name: res.userName,
            depart: res.postName,
            tag: res.tag,
            countCourse: res.totalStudyNum,
            partCourse: res.studyingNum,
            taskProgass: res.totalProgress === undefined ? '0' : res.totalProgress
          })
        })
      })
    },
    reFresh() {
      // this.getAllDepartment()
      this.getEmpByDepartId(this.departmentId)
    },
    getEmpByDepartId(id) {
      this.departmentId = id
      this.getLearnTaskByType()
    },
    deletByDepartId(data) {
      learn.batchClearDepartById(this.userId, data.departmentId).then(res => {
        if (res.res === 'success') {
          this.$message.success('学习任务重置成功')
          // this.getAllDepartment()
          this.getEmpByDepartId(this.departmentId)
        }
      })
    },
    addBatchTask(departMentId) {
      this.departmentId = departMentId
    },
    addTask() {
      this.setTitle = '设置'
      this.addLearningTask = true
    },
    upLearnTask(row) {
      this.departmentName = row.depart
      this.updateByUserId = row.id
      this.addLearningTask = true
      this.setTitle = '修改'
    },
    deteTask(row) {
      if (row.countCourse === 0) {
        return
      }
      if (row.id) {
        this.selectId = []
        this.selectId.push(row.id)
      }
      this.IsDelete = true
    },
    closeModal(data) {
      this.addLearningTask = data
    },
    closeDeletModal(data) {
      this.selectId = []
      this.IsDelete = data
    },
    IsdeteTask() {
      const payload = {
        operatorId: this.userId,
        userIds: this.selectId
      }
      learn.batchClearBySelectIds(payload).then(res => {
        if (res.res) {
          this.$message.success('学习任务删除成功')
          // this.getAllDepartment()
          this.getEmpByDepartId(this.departmentId)
          this.selectId = []
        }
      })
      this.IsDelete = false
    },
    selectTask(row) {
      this.selectId = row.map(r => r.id)
    },
    sDetail(row) {
      this.$router.push({
        name: 'taskDetail',
        params: {
          userId: row.id
        }
      })
    },
    sendTreeData() {
      this.$nextTick(() => {
        this.$refs.departments.getDepartment(this.departList)
      })
    },
    submitTask(departmentAddStudyTaskDTO) {
      departmentAddStudyTaskDTO.userIds = this.selectId
      !departmentAddStudyTaskDTO.IsUpTask
        ? learn.addLearnTask(departmentAddStudyTaskDTO).then(res => {
            if (res.code === 0) {
              this.$message.success('添加成功')
              // this.getAllDepartment()
              this.getEmpByDepartId(this.departmentId)
            }
          })
        : learn.updateTask(this.userId, this.updateByUserId, departmentAddStudyTaskDTO.studyTaskDTOS).then(res => {
            if (res.res) {
              this.$message.success('修改成功')
              // this.getAllDepartment()
              this.getEmpByDepartId(this.departmentId)
            }
          })
      this.addLearningTask = false
    }
  }
}
</script>

<style lang="less" scoped>
.tool-button {
  cursor: pointer;
  color: #448bff;
  &:hover {
    color: #2276ff;
  }
  &:first-child {
    margin-right: 10px;
  }
}
.content {
  display: flex;
  height: 100%;
  font-size: 14px;
}
.left {
  min-width: 280px;
  box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
  background: #fff;
  /*overflow: auto;*/
  height: calc(884 / 1080 * 100vh);
  .title {
    display: flex;
    align-items: center;
    padding-left: 93px;
    height: 50px;
    background: #e4e9ef;
    .icon {
      margin-right: 12px;
    }
  }
  .depart {
    padding: 10px 0;
    height: calc(884 / 1080 * 100vh - 50px);
    overflow: auto;
    ul {
      list-style: none;
      text-align: left;
      li {
        padding: 10px 0;
        line-height: 24px;
      }
    }
  }
}
.right {
  flex: auto;
  margin-left: 10px;
  width: calc(100% - 313px);
  .title {
    display: flex;
    align-items: center;
    padding-left: 30px;
    width: 100%;
    height: 62px;
    background: #fff;
    box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
    .btn {
      height: 40px;
      border-radius: 4px;
      .icon {
        margin-right: 4px;
      }
      &:first-child {
        margin-right: 20px;
      }
    }
  }
  .table {
    margin-top: 12px;
    height: calc(100% - 74px - 48px);
    max-height: 600px;
    flex: auto;
    overflow-y: auto;
    overscroll-behavior: contain;
    box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
    /*max-height: 450px;*/
    ::v-deep .ivu-table-body span {
      font-size: 14px;
      line-height: 25px;
      color: #5e6470;
      opacity: 1;
    }
    ::v-deep .ivu-table-header span {
      font-size: 16px;
      line-height: 28px;
      color: #3a4e64;
      font-weight: 400;
    }
    .iconStyle {
      font-size: 15px;
      .icon {
        cursor: pointer;
      }
      svg:first-child {
        margin-right: 5px;
      }
    }
    .taskTable {
      cursor: pointer;
      overflow: inherit !important;
      ::v-deep .ivu-table-header .ivu-table-cell {
        word-break: break-word;
      }
    }
  }
  .page {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: left;
    height: 48px;
  }
}
</style>
