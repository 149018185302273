<template>
  <div style="width: 100%;overflow: auto">
    <!--      <Tree-->
    <!--        :data="departData"-->
    <!--        :render="renderContent"-->
    <!--        class="task"/>-->
    <el-tree
      v-if="departData.length > 0"
      :data="departData"
      :current-node-key="departData[0].id"
      highlight-current
      node-key="id"
      default-expand-all
      :expand-on-click-node="false"
    >
      <div
        class="custom-tree-node"
        slot-scope="{ node, data }"
        style="display: flex;justify-content: space-between;width: 100%"
        @click="getEmplyoeeByDepartId(data)"
      >
        <Tooltip :content="data.name" :disabled="data.name.length <= 10" transfer max-width="250px">
          <div>
            {{ data.name.length > 10 ? data.name.substring(0, 10) + '...' : data.name }}
          </div>
        </Tooltip>

        <div style="display: flex;">
          <div title="设置学习任务" @click.stop="append(data)">
            <Icon type="ios-add-circle-outline" />
          </div>
          <div title="清除学习任务" @click.stop="remove(data)">
            <Icon type="ios-trash-outline" />
          </div>
        </div>
      </div>
    </el-tree>
    <Modal v-model="addModal" width="484" footer-hide :mask-closable="false" @on-cancel="close">
      <div class="title">{{ title }}</div>
      <div class="footer">
        <Button @click="close">取消</Button>
        <Button @click="add" type="primary">确定</Button>
      </div>
    </Modal>
    <div v-if="deleModal">
      <Modal v-model="deleModal" width="484" footer-hide :mask-closable="false" @on-cancel="close">
        <div class="title">{{ title }}</div>
        <div class="footer">
          <Button @click="close">取消</Button>
          <Button @click="delTask" type="primary">确定</Button>
        </div>
      </Modal>
    </div>
    <div v-if="addLearningTask">
      <learnTask :title="setTitle" :open="addLearningTask" @addLearnTask="addDepartTask" :IsDepart="true" @close="closeModal" />
    </div>
  </div>
</template>

<script>
import learnTask from '../type/learnTask'
import learn from '../../../../api/learnTask'
export default {
  name: 'taskTree',
  components: { learnTask },
  data() {
    return {
      departData: [],
      buttonProps: {
        type: 'default',
        size: 'small'
      },
      addModal: false,
      title: '',
      selectNode: '',
      departMentId: 0,
      addLearningTask: false,
      deleModal: false,
      setTitle: '',
      th: document.body.clientHeight - 305
    }
  },
  methods: {
    append(data) {
      this.departMentId = data.departmentId || data.id
      this.title = '批量添加' + data.name + '下所有人的学习任务'
      this.addModal = true
    },
    close() {
      this.addModal = false
      this.deleModal = false
    },
    add() {
      this.setTitle = '设置'
      this.addLearningTask = true
      this.$emit('departmentId', this.departMentId)
      this.addModal = false
    },
    closeModal() {
      this.addLearningTask = false
    },
    remove(data) {
      this.deleModal = true
      this.departMentId = data.departmentId
      this.title = '确认删除' + data.name + '岗位下所有人得学习任务吗?'
    },
    delTask() {
      // let data = {
      //   id: this.departMentId
      // }
      // this.getEmplyoeeByDepartId(data)
      const payload = {
        departmentId: this.departMentId
      }
      this.$emit('deleDepartAllEmply', payload)
      this.deleModal = false
    },
    getDepartment(data) {
      this.$nextTick(() => {
        this.departData = data
      })
    },
    addDepartTask(departmentAddStudyTaskDTO) {
      learn.addLearnTaskByDepart(this.departMentId, departmentAddStudyTaskDTO).then(res => {
        if (res.res === 'success') {
          this.$message.success('添加成功')
          this.$emit('add-ok')
          this.addLearningTask = false
        }
      })
    },
    getEmplyoeeByDepartId(node) {
      this.$emit('departmentById', node.id || node.departmentId)
    }
  }
}
</script>

<style lang="less" scoped>
.demo-tree-render .ivu-tree-title {
  width: 100%;
}
.task {
  cursor: pointer;
  ::v-deep .ivu-tree-children li {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #3a4e64;
  }
}
.title {
  margin: 25px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
.footer {
  margin: 20px 0;
  text-align: center;
  button {
    width: 90px;
    height: 35px;
    border-radius: 4px;
  }
  button:first-child {
    margin-right: 14px;
  }
}
.task /deep/ .ivu-tree-title {
  width: 100%;
}
</style>
